// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-test-js": () => import("./../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-apply-js": () => import("./../src/templates/apply.js" /* webpackChunkName: "component---src-templates-apply-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-calendar-js": () => import("./../src/templates/calendar.js" /* webpackChunkName: "component---src-templates-calendar-js" */),
  "component---src-templates-contact-us-js": () => import("./../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-course-js": () => import("./../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-events-js": () => import("./../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-geekforce-js": () => import("./../src/templates/geekforce.js" /* webpackChunkName: "component---src-templates-geekforce-js" */),
  "component---src-templates-geekpal-js": () => import("./../src/templates/geekpal.js" /* webpackChunkName: "component---src-templates-geekpal-js" */),
  "component---src-templates-geeks-vs-others-js": () => import("./../src/templates/geeks-vs-others.js" /* webpackChunkName: "component---src-templates-geeks-vs-others-js" */),
  "component---src-templates-graduates-js": () => import("./../src/templates/graduates.js" /* webpackChunkName: "component---src-templates-graduates-js" */),
  "component---src-templates-index-js": () => import("./../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-job-js": () => import("./../src/templates/job.js" /* webpackChunkName: "component---src-templates-job-js" */),
  "component---src-templates-jobs-js": () => import("./../src/templates/jobs.js" /* webpackChunkName: "component---src-templates-jobs-js" */),
  "component---src-templates-location-js": () => import("./../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-partners-js": () => import("./../src/templates/partners.js" /* webpackChunkName: "component---src-templates-partners-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-pricing-js": () => import("./../src/templates/pricing.js" /* webpackChunkName: "component---src-templates-pricing-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-test-js": () => import("./../src/templates/test.js" /* webpackChunkName: "component---src-templates-test-js" */),
  "component---src-templates-thank-you-js": () => import("./../src/templates/thank-you.js" /* webpackChunkName: "component---src-templates-thank-you-js" */),
  "component---src-templates-the-academy-js": () => import("./../src/templates/the-academy.js" /* webpackChunkName: "component---src-templates-the-academy-js" */)
}

